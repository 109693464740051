import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import "react-chat-elements/dist/main.css"


import { BrowserRouter, Routes, Route } from "react-router-dom";
import dayjs from "dayjs";


import AuthProvider from './core/auth/AuthenticationPrivuder';
import { Notifications } from '@mantine/notifications';
import ChatsTable from './features/chat/ChatsTable';
import { InChatPage } from './features/in_chat/InChatPage';



export default function App() {

  dayjs.locale('ar');


  return (

    <MantineProvider defaultColorScheme="auto" withCssVariables={true} withGlobalClasses={true} theme={
      {
        fontFamily: 'Alexandria, sans-serif',

      }

    }

    >
      <Notifications />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<ChatsTable />} />
            <Route path="/in-chat"
              element={<InChatPage />}
            />

            {/* <Route element={<PrivateRoute />}>
              <Route path="/" element={<AllCarsListing />} />
              <Route path="/settings" element={<SettingsPage />} />

              <Route element={<RoleBasedRoute allowedRoles={[
                'manager'
              ]} />}>
                <Route path="/employees" element={<EmployeesPage />} />
                <Route path="/customers" element={<ClientsPage />} />
                <Route path="/statistics" element={<StatisticsPage />} />


              </Route>
            </Route> */}


          </Routes>
        </AuthProvider>
      </BrowserRouter>




    </MantineProvider >
  );
}


