import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserModel from "./models/UserModel";
import CLIENT from "../client/client";
import { AxiosError } from "axios";


const AuthContext = createContext<any>(null);


export function retrieveTokenFromLocalStorage(): string | undefined {
    const userRaw = localStorage.getItem("user");

    if (userRaw == null || userRaw == undefined) return undefined;

    const token = JSON.parse(userRaw).accessToken ?? JSON.parse(userRaw).token;

    console.log("GOT TOKEN " + token);
    return token;
}

function retrieveUsersFromLocalStorage(): UserModel | undefined {
    const userRaw = localStorage.getItem("user");

    if (userRaw == null || userRaw == undefined) return undefined;

    const user = JSON.parse(userRaw);

    console.warn("AS USER \n ");
    console.warn(user);
    return user;
}


const AuthProvider = ({ children }: { children: any }) => {
    const [user, setUser] = useState<UserModel | null>(retrieveUsersFromLocalStorage() || null);
    const [token, setToken] = useState(retrieveTokenFromLocalStorage() || null);
    const navigate = useNavigate();

    /// logs user in
    const loginAction = async (data: { phone_number: string, password: string }) => {
        try {
            console.log(data);

            const res = (await CLIENT().post('/auth/login', data)).data;



            if (res != null) {
                const resUser = (await CLIENT(res.accessToken).get("/auth/user")).data;

                console.log(resUser);

                const session: UserModel = {
                    token: res.accessToken,
                    id: resUser.id,
                    name: resUser.name,
                    email: resUser.email,
                    phone_number: resUser.phone_number,
                    email_verified_at: resUser.email_verified_at == null ? null : new Date(Date.parse(resUser.email_verified_at)),
                    role: resUser.role,
                    created_at: new Date(Date.parse(resUser.created_at)),
                };

                setUser(
                    session
                );

                setToken(res.accessToken);

                localStorage.setItem("user", JSON.stringify(session));

                navigate("/");
                return true;
            }
            throw new Error(res.message);
        } catch (err) {
            if (err === AxiosError) {
                console.error((err as any).response.data ?? "");
            } else { console.error(err); }
            throw 'error';
        }
    };

    // logs user out
    const logOut = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem("user");
        navigate("/auth");
    };

    return <AuthContext.Provider value={{
        user: user, token: token, logout: logOut, login: loginAction,
    }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};