import { Space, Flex, Group, ActionIcon, Button, Text } from "@mantine/core";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { FunctionComponent } from "react";

interface Action {
    label: string,
    onClick: () => void
}

interface SectionHeadProps {
    mainAction?: Action,
    secondaryAction?: Action,
    onFilter?: () => void,
    children?: any,
    title: string,
    subtitle?: string,
    filtering?: boolean
}

const SectionHead: FunctionComponent<SectionHeadProps> = ({
    mainAction, secondaryAction, children, onFilter, subtitle, title, filtering,
}) => {
    return (
        <div>
            <Space h="sm" />

            {/* // HEADER */}
            <Flex justify={"space-between"} align={'center'}>
                <Group>
                    <Flex direction={'column'} justify={'start'}>
                        <Group>
                            <h2>{title}</h2>
                            {onFilter && <ActionIcon variant={filtering == true ? "gradient" : "default"} aria-label="Settings" onClick={onFilter}>
                                <IconAdjustmentsHorizontal style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>}
                        </Group>


                        {subtitle && <Text size="sm" >
                            {subtitle}
                        </Text>}


                    </Flex>
                </Group>

                <Group >

                    {children ||
                        <Group>
                            {secondaryAction && <Button variant='outline' onClick={
                                secondaryAction?.onClick
                            }>
                                {
                                    secondaryAction?.label

                                }
                            </Button>}
                            {mainAction && <Button onClick={
                                mainAction?.onClick
                            }>
                                {mainAction?.label}
                            </Button>}
                        </Group>}

                </Group>
            </Flex>

            <Space h="lg" /></div>
    );
}

export default SectionHead;