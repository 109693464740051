

import axios, { Axios } from 'axios';
import { retrieveTokenFromLocalStorage } from '../auth/AuthenticationPrivuder';

axios.defaults.baseURL = "https://prod.voxegroupdev.link/api/diverge_dashboard"
// axios.defaults.baseURL = ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? "https://voxegroupdev.link/api/diverge_dashboard" :

//     "https://prod.voxegroupdev.link/api/diverge_dashboard"
// );

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        console.log(error);
    }
    return error;
});


function CLIENT(token?: string): Axios {

    return axios.create({
        headers: {
            common: {
                // 'Authorization': 'Bearer ' + (token ?? retrieveTokenFromLocalStorage()),
                'Accept': 'application/json'
            }
        }
    });
};

export default CLIENT;
