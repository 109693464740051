import { AppShell, Box, Button, Center, Container, Flex, Image, Group, Loader, Paper, ScrollArea, SimpleGrid, Space, Text, rgba, useMantineColorScheme, Modal, Code, JsonInput, useMantineTheme } from "@mantine/core";
import { FunctionComponent, useEffect, useRef, useState } from "react"
import CopyToClipBoard from "../../components/CopyToClipBoard";
import { useLocation, useNavigate } from "react-router-dom";
import { TextBoxReadOnly } from "../../components/TextBoxReadOnly";
import { IconArrowBack, IconAutomation, IconFunction, IconFunctionOff, IconMoon, IconScribble, IconScribbleOff, IconSun } from "@tabler/icons-react";
import CLIENT from "../../core/client/client";
import CENTER_STYLE from "../../core/CenterStyle";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput } from '@chatscope/chat-ui-kit-react';
import { useDisclosure } from "@mantine/hooks";



interface InChatPageProps {
}

interface GeneralInfo {
    id: string,
    title: string, content: string,
}

interface Item {
    "id": string,
    "metadata": {
        facebook_link?: string,
        "page_id": number,
        "name": string,
        "price": any,
        "parent_id": number,
        "category_id": number,
        "image_paths": string[],
        "children":
        {
            "id": string,
            "metadata": {
                "name": string,
                "price": number
            }
        }[]

    }
}

interface AIMessage {
    "content": string,
    "usage": {
        "completion_tokens": number,
        "prompt_tokens": number,
        "total_tokens": number
    },
    "function_calls":
    {
        "name": string,
        "arguments": string,
        "response": string
    }[]

}


interface ConversationMessage {
    "id": number,
    "conversation": number,
    "online_message_id": string,
    "message_content": string,
    "time_created": Date,
    "reply_to": number,
    "sender_id": string,
    "spontaneous_uuid": string,
    "divergence": AIMessage,
    "snapshot": AIMessage,
    "attachment":
    {
        "file_type": string,
        "file_link": string
    }[],
    "is_from_anon_user": true
}



export const InChatPage: FunctionComponent<InChatPageProps> = () => {
    const location = useLocation();
    const nav = useNavigate();

    const id = location.state?.chat_id ?? 1;

    const [loading, setLoading] = useState<boolean>(true);



    const [openedItemsDialog, itemsDialog] = useDisclosure(false);


    const [systemPrompt, setSystemPrompt] = useState<string>();
    const [generalInfo, setGeneralInfo] = useState<GeneralInfo[]>();
    const [items, setItems] = useState<Item[]>();
    const [chatMessages, setChatMessages] = useState<ConversationMessage[]>();
    const [selectedItem, setSelectedItem] = useState<Item>();
    const mantineColorScheme = useMantineColorScheme();
    const [scrollAllTogether, setScrollAllTogether] = useState<boolean>(true);
    const [viewFunctionCalls, setViewFunctionCalls] = useState<boolean>(true);

    const ref = useRef();

    async function onLoad() {
        setLoading(true);
        const prompt = await loadSystemPrompt(id);
        setSystemPrompt(prompt ?? undefined);
        const info = await loadGeneralInfo(id);
        setGeneralInfo(info ?? undefined);
        const itemsRaw = await loadAllItems(id);
        setItems(itemsRaw ?? undefined);
        const rawChat = await loadMessages(id);
        setChatMessages(rawChat ?? undefined);
        setLoading(false);
    }
    useEffect(() => {
        onLoad();
    }, []);


    function renderDivergences(snap: boolean, viewFunctionCalls: boolean): any {
        let result = [];

        for (let m of (chatMessages ?? [])) {
            if (m.attachment != undefined && m.attachment != null) {
                for (let att of m.attachment) {
                    result.push(
                        <Message

                            model={{
                                sentTime: m.time_created.toString(),
                                // sender?:e.;

                                direction: m.is_from_anon_user ? 'outgoing' : 'incoming',
                                position: "single",
                                type: att.file_type == 'image' ? 'image' : 'text',
                                payload: att.file_type == 'image' ? att.file_link : att.file_type,

                            }}>

                            {
                                att.file_type == 'image' && <Message.ImageContent src={att.file_link} alt="Image" />

                            }
                        </Message>
                    );
                }
            }

            if (m.is_from_anon_user) {
                if (m.message_content != '' && m.message_content != undefined) {
                    result.push(
                        <Message model={{
                            sentTime: m.time_created.toString(),
                            // sender?:e.;

                            direction: m.is_from_anon_user ? 'outgoing' : 'incoming',
                            position: "single",
                            type: 'text',
                            payload: m.message_content,
                        }}></Message>
                    );
                }



                continue;
            }

            let sampleFrom = snap ? m.snapshot : m.divergence;

            if (sampleFrom?.function_calls != null && viewFunctionCalls == true) {
                for (let diver of sampleFrom.function_calls) {
                    result.push(
                        <Center>
                            <Box color="white" bg={th.colors.gray[0]} style={{ borderRadius: '0.5rem' }} m={'xs'}>
                                <Flex justify={'center'} align={'stretch'} style={{ fontSize: 11, bg: 'white' }} direction={'column'} px={'sm'}>
                                    <Space h={4}></Space>
                                    <Text size="xs" >TOOL CALL: {diver.name}</Text>
                                    <Space h={8}></Space>
                                    <Text size="xs">{diver.arguments.replaceAll('\n', '')}</Text>
                                    <p >{diver.response}</p>

                                </Flex>
                            </Box>
                        </Center>
                    );
                }
            }

            sampleFrom?.content == null || sampleFrom?.content == undefined ? console.log('s') : result.push(
                <Message model={{
                    sentTime: m.time_created.toString(),
                    // sender?:e.;

                    direction: 'incoming',
                    position: "single",
                    type: 'text',
                    payload: sampleFrom.usage != null ? sampleFrom.content + '\n' + `Completion: ${sampleFrom.usage.completion_tokens}, Prompt: ${sampleFrom.usage.prompt_tokens}, Total: ${sampleFrom.usage.total_tokens}` : sampleFrom.content,
                }}></Message>
            );




        }

        return result;

    }

    const th = useMantineTheme();

    /// U I
    if (loading) return <div style={CENTER_STYLE}><Loader /></div>;

    return (

        <AppShell
            dir="ltr"

            navbar={{
                width: 320,
                breakpoint: 'sm',
            }}
            padding="sm"
        >

            <Modal opened={openedItemsDialog} onClose={itemsDialog.close} size={'80%'} title="معلومات العنصر ITEM">
                <Group grow>
                    <a href={(selectedItem?.metadata as any)?.facebook_link ?? ""} target="_blank"><Button>Facebook Link</Button></a>
                </Group>
                <Space h={12} />

                <Text>PRICE.: {selectedItem?.metadata?.price.price}</Text>
                <Space h={12} />
                <JsonInput
                    label="JSON Data Dump"
                    placeholder="Textarea will autosize to fit the content"
                    value={JSON.stringify(selectedItem, null, 2)}
                    validationError="Invalid JSON"
                    formatOnBlur
                    autosize
                    minRows={4}
                />
            </Modal>
            <AppShell.Navbar p="sm"  >
                <ScrollArea scrollbars={'y'}  >
                    <Space h={'xs'}></Space>
                    <Flex direction={'row'} justify={'stretch'} gap={'xs'}>
                        <Button flex={1} size="xs" variant='light' color="red" onClick={() => nav('/')}><IconArrowBack></IconArrowBack></Button>
                        <Button flex={1} size="xs" variant='light' onClick={() => {
                            const setScheme = mantineColorScheme.colorScheme == 'auto' ? 'dark' : mantineColorScheme.colorScheme == 'dark' ? 'light' : 'auto';
                            mantineColorScheme.setColorScheme(setScheme);

                        }}>
                            {
                                mantineColorScheme.colorScheme == 'auto' ? <IconAutomation /> : mantineColorScheme.colorScheme == 'dark' ? <IconMoon /> : <IconSun></IconSun>
                            }
                        </Button>
                        <Button flex={7} variant='outline' size="xs" px={0} onClick={onLoad}>اعادة التحميل</Button>
                    </Flex>

                    <Space h={'sm'}></Space>


                    <Flex direction={'row'} justify={'stretch'} gap={'sm'}>
                        <Button flex={1} size="md" variant='light' color="cyan" onClick={() => setScrollAllTogether(!scrollAllTogether)}>
                            {scrollAllTogether ? <IconScribble /> : <IconScribbleOff />}
                        </Button>
                        <Button flex={1} size="md" variant='light' color="cyan" onClick={() => setViewFunctionCalls(!viewFunctionCalls)}>
                            {viewFunctionCalls ? <IconFunction /> : <IconFunctionOff />}
                        </Button>

                    </Flex>
                    <Space h={'md'}></Space>
                    <TextBoxReadOnly title={"system prompt"} content={systemPrompt} height={364}
                    />
                    <Space h={'lg'}></Space>
                    <TextBoxReadOnly title={"General Info"} content={generalInfo?.map(e => `#### ${e.title}\n${e.content}`).join('\n')} height={200}
                    />
                    <Space h={'lg'}></Space>

                    <Text size="md">{"العناصر (items)"}</Text>
                    <Space h={8} />

                    <SimpleGrid cols={2}>
                        {items?.map(e => <Paper withBorder p={4} onClick={() => {
                            setSelectedItem(e);
                            itemsDialog.open();
                        }}>
                            <Flex direction={'column'}>
                                <Text>{e.metadata.name}</Text>
                                <Space h={6} />

                                <Image src={e.metadata?.image_paths[0] ?? ''}></Image>
                            </Flex>

                        </Paper>
                        )}

                    </SimpleGrid>
                    <Space h={'lg'}></Space>


                </ScrollArea>


            </AppShell.Navbar>

            <AppShell.Main >
                {/* {loading == true ?
                    <div style={CENTER_STYLE}>
                        <Loader></Loader>
                    </div >
                    : <>

                    </>} */}

                <Group grow gap={'sm'} align="top">
                    {/* THESE ARE TO BE # */}
                    <ChatView title="مع السياق" scrollAllTogether={scrollAllTogether}>  {
                        renderDivergences(true, viewFunctionCalls).reverse()
                    }
                    </ChatView>

                    <ChatView title="بدون سياق" scrollAllTogether={scrollAllTogether}>
                        {
                            renderDivergences(false, viewFunctionCalls).reverse()
                        }
                    </ChatView>
                    <ChatView title="رد الادمن" scrollAllTogether={scrollAllTogether}>
                        {
                            chatMessages?.map(e => <Message model={{
                                sentTime: e.time_created.toString(),
                                // sender?:e.;

                                direction: e.is_from_anon_user ? 'outgoing' : 'incoming',
                                position: "single",
                                type: 'text',
                                payload: e.message_content,
                            }}>
                                {
                                    e.attachment.map(e => e.file_type == 'image' ?
                                        <Message.ImageContent src={e.file_link} alt="Image" />


                                        : undefined)
                                }
                            </Message>).reverse()
                        } </ChatView>
                </Group>

            </AppShell.Main>


        </AppShell>);
}



interface ChatViewProps {
    title: string,
    scrollAllTogether: boolean;
    children: any;
}
const ChatView: FunctionComponent<ChatViewProps> = ({ title, scrollAllTogether, children }: ChatViewProps) => {

    const ref = useRef();
    const RENDER = <Box h={scrollAllTogether ? undefined : window.innerHeight * 0.875} >

        <ChatContainer style={
            {
                background: rgba('#000000', 0.0)
            }
        }>

            <MessageList

                style={
                    {
                        background: rgba('#000000', 0.0)
                    }
                }>
                {children}
                {/* <Center>tol crp</Center> */}
            </MessageList>


        </ChatContainer></Box>
        ;

    return <Flex direction={'column'} align={'end'} >
        <Text size="md" fw={'bold'}>{title}</Text>
        <Space h={'sm'} />
        <Paper withBorder py={'sm'} mah={'100%'} w={'100%'}>
            {scrollAllTogether ? RENDER : <ScrollArea>

                {RENDER}
            </ScrollArea>}
        </Paper>

    </Flex >;
}





async function loadSystemPrompt(chatId: number): Promise<string | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/conversations/" + chatId + "/system-prompt/", {
            params: { conversation_id: chatId }
        })

        console.log(res.data.data);

        return res.data.system_prompt;
    } catch (e) {
        // checkUnAuthenticated(e, auth);
        alert(e);
        console.error(e);
        return null;
    }

}
async function loadGeneralInfo(chatId: number): Promise<GeneralInfo[] | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/conversations/" + chatId + "/general-info/", {
            params: { conversation_id: chatId }
        })

        console.log(res.data.data);

        return (res.data as Array<any>).map((e: any) => ({ id: e.id, title: e.data.title, content: e.data.content } as GeneralInfo));
    } catch (e) {
        // checkUnAuthenticated(e, auth);
        alert(e);
        console.error(e);
        return null;
    }

}

async function loadAllItems(chatId: number): Promise<Item[] | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/conversations/" + chatId + "/items/", {
            params: { conversation_id: chatId }
        })

        console.log(res.data.data);

        return (res.data as Array<any>).map((e: any) => (e as Item));
    } catch (e) {
        // checkUnAuthenticated(e, auth);
        alert(e);
        console.error(e);
        return null;
    }

}

async function loadMessages(chatId: number): Promise<ConversationMessage[] | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/conversations/" + chatId + "/messages/", {
            params: { conversation_id: chatId }
        })

        console.log(res.data.data);

        return (res.data as Array<any>).map((e: any) => (e as ConversationMessage));
    } catch (e) {
        // checkUnAuthenticated(e, auth);
        alert(e);
        console.error(e);
        return null;
    }

}