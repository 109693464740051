import { ActionIcon } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCopy } from "@tabler/icons-react";
import { FunctionComponent } from "react";

interface CopyToClipBoardProps {
    data: string
}

const CopyToClipBoard: FunctionComponent<CopyToClipBoardProps> = ({ data }) => {


    const clipboard = useClipboard({ timeout: 1000 });

    return (<ActionIcon variant="subtle" onClick={() => {
        clipboard.copy(data);

        notifications.show({
            title: "تم نسخ النص", message: "تم نسخ النص المختار الى حافظة بنجاح"
        });
    }} >
        <IconCopy></IconCopy>
    </ActionIcon>);
}

export default CopyToClipBoard;