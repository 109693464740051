import { FunctionComponent, useEffect, useState } from "react";
import { MainLayout } from "../../layout/MainLayout";
import { Button, Center, Drawer, Flex, Pagination, ScrollArea, Space, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import SectionHead from "../../components/SectionHead";
import { useAuth } from "../../core/auth/AuthenticationPrivuder";

import PaginatedResponse from "../../core/paginated_response";
import CLIENT from "../../core/client/client";
// import ConversationDataForm from "./ConversationDataForm";
import { formatDate } from "../../core/format_date";
import checkUnAuthenticated from "../../core/check_un_authenticated";
import { UserModelInfo } from "../../core/users_management/user_management_utils";
import { Link, useNavigate } from "react-router-dom";

interface ChatsTableProps {

}

export interface ConversationStats {
    cars_count?: number;
    last_purchase: string | { created_at: string } | null; // Assuming last_purchase can be a string or null
}



export interface Conversation {
    "id": number,
    "platform": string,
    "page_id": number,
    "uuid": string,
    "Conversation_id": string,
    "time_created": Date,
    "flag": {},
    "pinned": true,
    "hand_over": true,
    "phone_number": string,
    "Address": string,
    "booking_date": Date
}



const ChatsTable: FunctionComponent<ChatsTableProps> = () => {
    const [addConversation, { open, close }] = useDisclosure(false);
    const auth = useAuth();

    const [loading, setLoading] = useState(true);

    const [currentConversations, setConversations] = useState<PaginatedResponse<Conversation> | null>(null);

    /// used when each addConversation is used to determine what to load on the dialog drawer
    const [selectedConversation, setSelectedChat] = useState<Conversation | null>(null);

    function onLoad(withPage?: number) {
        setLoading(true)

        loadAllClients(withPage, auth).then(e => {
            setConversations(e)
            console.log(e);
            setLoading(false)
        });
    }

    function selectAndOpenConversation(c: Conversation) {
        setSelectedChat(c);
        open();
    }

    useEffect(onLoad, []);

    const nav = useNavigate();
    const st = (e: any) => e.stopPropagation();
    return (

        <MainLayout loading={loading} selectedRoute="/">
            <Drawer
                size={'lg'}
                opened={addConversation} onClose={() => {
                    setSelectedChat(null);
                    close();
                }}
            >
                {/* <ConversationDataForm initial={selectedConversation ?? undefined} close={() => {
                    close();
                    setSelectedChat(null);

                    onLoad();

                }} onSubmit={() => { }} /> */}
            </Drawer>


            {/* HEAD */}
            <SectionHead title={'أدارة الشات'}>
                <Button variant='outline' onClick={
                    () => onLoad()
                }>
                    أعادة التحميل
                </Button>
                {/* <Button onClick={
                    () => {
                        open()
                    }
                }>
                    اضافة 
                </Button> */}
            </SectionHead>

            <Space h="sm" />


            {/* TABLE */}
            <ScrollArea>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>المعرف id</Table.Th>
                            <Table.Th>platform</Table.Th>
                            <Table.Th>رقم الهاتف</Table.Th>
                            <Table.Th>العنوان</Table.Th>
                            <Table.Th>تاريخ الانشاء</Table.Th>
                            <Table.Th>pageId</Table.Th>
                            <Table.Th>uuid</Table.Th>
                            <Table.Th>اجرائات</Table.Th>
                            {/* <Table.Th>cId</Table.Th>
                            <Table.Th>booking</Table.Th>
                            <Table.Th>pinned</Table.Th>
                            <Table.Th>handover</Table.Th> */}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{
                        currentConversations?.data?.map((element) => (
                            <Table.Tr key={element.id}  >

                                <Table.Td onDoubleClick={st}>{element.id}</Table.Td>
                                <Table.Td onDoubleClick={st}>{element.platform}</Table.Td>
                                <Table.Td maw={172} dir="ltr" align="right">{element.phone_number ?? 'None'}</Table.Td>
                                <Table.Td onDoubleClick={st}>{element.Address ?? 'None'}</Table.Td>
                                <Table.Td onDoubleClick={st}>{formatDate(element?.time_created ?? "")}</Table.Td>
                                <Table.Td onDoubleClick={st}>{element.page_id}</Table.Td>
                                <Table.Td onDoubleClick={st}>{element.uuid}</Table.Td>
                                <Table.Td >
                                    <Button variant="outline" size="xs" onClick={
                                        () => {
                                            nav('/in-chat', {
                                                state: { chat_id: element.id }
                                            });
                                        }
                                    }>
                                        الدخول للمحادثة
                                    </Button>
                                </Table.Td>
                                {/* <Table.Td>{element.Conversation_id}</Table.Td>
                                <Table.Td>{formatDate(element?.booking_date ?? "")}</Table.Td>
                                <Table.Td>{element.pinned}</Table.Td>
                                <Table.Td>{element.hand_over}</Table.Td> */}

                            </Table.Tr>
                        ))}</Table.Tbody>
                </Table>
            </ScrollArea>

            <Space h={'lg'}></Space>

            <Center>
                <Pagination

                    total={currentConversations?.total ?? 1} siblings={1} defaultValue={currentConversations?.current ?? 1} onChange={
                        (e) => onLoad(e)
                    } />
            </Center>

            <Space h={'lg'}></Space>


        </MainLayout >);
}


async function loadAllClients(withPage?: number, auth?: any): Promise<PaginatedResponse | null> {
    try {
        const c = CLIENT();

        const res = await c.get("/conversations/", {
            params: { page: withPage, sync: true }
        })

        console.log(res.data.results);

        return {
            data: res.data.results,
            ...res.data,
        };
    } catch (e) {
        // checkUnAuthenticated(e, auth);
        return null;
    }

}

export default ChatsTable;