import { Flex, Space, ScrollArea, Paper, Text, useMantineTheme, useMantineColorScheme, Box, Group, Modal } from "@mantine/core"
import { FunctionComponent } from "react"
import CopyToClipBoard from "./CopyToClipBoard"
import ReactMarkdown from 'react-markdown'
import { IconArrowUpRight, IconEyeTable } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";



interface TextBoxReadOnlyProps {
    title: string,
    content?: string,
    height?: number,
}

export const TextBoxReadOnly: FunctionComponent<TextBoxReadOnlyProps> = ({ title, content, height = 500 }: TextBoxReadOnlyProps) => {
    const th = useMantineTheme();
    const cs = useMantineColorScheme();

    const [opened, { open, close }] = useDisclosure(false);

    return <>
        <Modal opened={opened} onClose={close} title={title} size={'75%'}>
            <ReactMarkdown  >{content}</ReactMarkdown>

        </Modal>
        < Flex justify={'space-between'} align={'center'} >
            <Group>
                <CopyToClipBoard data={content ?? ""}></CopyToClipBoard>
                < IconArrowUpRight onClick={open}></IconArrowUpRight>
            </Group>


            <Text size="md" fw={'bold'}>{title}</Text>
        </Flex >
        <Space h={'xs'}></Space>
        <Paper w='100%' withBorder  >

            <ScrollArea style={{ overflowX: 'clip' }}>
                <Box h={height} my={'sm'} mx={'md'}>
                    <ReactMarkdown  >{content}</ReactMarkdown>

                </Box>

            </ScrollArea >
        </Paper>
    </>
};